<template>
  <div class="">
    <center-title title="汇款管理"></center-title>
    <el-table :data="tableData" style="width:100%">
      <el-table-column align="center" prop="company_name" label="客户名称" width="180">
      </el-table-column>
      <el-table-column align="center" prop="settle_sn" label="对账单号" width="220">
      </el-table-column>
      <el-table-column align="center" prop="deal_order_count" label="对账订单数" width="120">
      </el-table-column>
      <el-table-column align="center" prop="business_money" label="账单金额" width="120">
      </el-table-column>
      <el-table-column align="center" prop="quota_money" label="已用额度" width="120">
      </el-table-column>
      <!-- <el-table-column align="center" prop="status_text" label="付款状态"></el-table-column> -->
      <!-- <el-table-column align="center" prop="status_text" label="对账单状态" width="120">
      </el-table-column> -->
      <el-table-column align="center" prop="create_time" label="状态" width="120">
        <template slot-scope="scope">
          {{ scope.row.status_text }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="create_time" label="创建时间" width="220">
      </el-table-column>
      <el-table-column align="center" prop="step" label="操作" fixed="right" width="120">
        <template slot-scope="scope">
          <div class="primary mb5" @click="qrIsPay(scope.row.id)" v-if="scope.row.status === 4">确认付款</div>
          <!-- <router-link class="" :to="`/remitmoneydetail?id=${scope.row.id}`">详情</router-link> -->
          <div class="mb5">
            <router-link class="" :to="`/remitmoneydetail?id=${scope.row.id}&type=2`">已付款</router-link>
          </div>
          <router-link class="" :to="`/remitmoneydetail?id=${scope.row.id}&type=1`">未付款</router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      tableData: [],
      formInline: {
        user: "",
        region: "",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    getLists() {
      this.$api.getRemitMoneyApi().then((res) => {
        this.tableData = res.data.list
      });
    },
    qrIsPay(id) {
      this.$api
        .getRmOperateApi({
          id: id,
          type: 5,
        })
        .then((res) => {
          this.$message({
            message: "确认成功",
            type: "success",
          });
          this.getLists()
        });
    }
  },
  created() {
    this.getLists();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
/deep/ .el-form {
  .el-form-item__content,
  .el-input__inner {
    height: 32px;
    line-height: 32px;
    width: 240px;
    border-radius: 0;
  }

  .el-input__icon {
    line-height: 32px;
  }
}

/deep/ .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 0px 20px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>